export const CacheQueryTypes = {
  accountMetric: "AccountMetricQuery",
  afiliateProgram: "AfiliateProgramQuery",
  bankAccount: "BankAccountQuery",
  brandResource: "BrandQuery",
  campaignResource: "CampaignQuery",
  chat: "ChatQuery",
  countryCity: "CountryCityQuery",
  ecommerce: "EcommerceQuery",
  gamification: "GamificationQuery",
  generic: "GenericEnumsQuery",
  mobileVersion: "MobileVersionQuery",
  notification: "NotificationQuery",
  payments: "PaymentsQuery",
  platform: "PlatformQuery",
  postulationResource: "PostulationQuery",
  product: "ProductQuery",
  report: "ReportQuery",
  review: "ReviewsQuery",
  socialNetworkUser: "SocialNetworkUserQuery",
  subscription: "SubscriptionsQuery",
  userResource: "UserQuery",
  wallet: "WalletQuery",
  workOrder: "WorkOrderQuery",
  workspace: "WorkspaceQuery",
};
