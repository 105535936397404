import { PluginFunc } from 'dayjs';

const defaultFormats = {
  sameDay: '[Hoy]',
  nextDay: '[Mañana]',
  nextWeek: '[Próxima semana]',
  lastDay: '[Ayer]',
  lastWeek: 'dddd',
  sameElse: 'DD [de] MMM [de] YYYY',
};

const plugin: PluginFunc<never> = (option, dayjsClass) => {
  dayjsClass.prototype.calendarFormat = function (sameElseFormat) {
    const formatted = this.calendar(null, {
      ...defaultFormats,
      ...sameElseFormat,
    });
    return formatted.charAt(0).toUpperCase() + formatted.slice(1);
  };
};

declare module 'dayjs' {
  interface Dayjs {
    calendarFormat(sameElseFormat?: {
      sameDay?: string;
      nextDay?: string;
      nextWeek?: string;
      lastDay?: string;
      lastWeek?: string;
      sameElse?: string;
    }): string;
  }
}

export default plugin;
