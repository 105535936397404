import { PluginFunc } from 'dayjs';

const plugin: PluginFunc<never> = (option, dayjsClass) => {
  dayjsClass.prototype.graphqlDateFormat = function () {
    return this.format('YYYY-MM-DD');
  };
};

declare module 'dayjs' {
  interface Dayjs {
    graphqlDateFormat(): string;
  }
}

export default plugin;
